const ressources = [
  "mana",
  "lifestyle",
  "military",
  "politics",
  "economy",
  "dark",
  "light",
  "knowledge",
  "science",
  "religion",
  "life",
  "love",
  "espionage",
  "rage",
];

const schools = [
  { id: "top_left" },
  { id: "top_right" },
  { id: "bottom_left" },
  { id: "bottom_right" },
];

const availableLanguages = ["english", "german"];

export { ressources, schools, availableLanguages };

const test = () => {}