import React from "react";

const LevelsSupPage = () => {
  return (
    <div className="divColumn" style={{ height: "100%" }}>
      LevelsSupPage
    </div>
  );
};

export default LevelsSupPage;
